<template>
  <div>
    <PuckDialog
      v-if="showDialog"
      @close="showDialog = false"
    />
    <PuckList
      :pucks="pucks"
      step="50"
      length="50"
    />
  </div>
</template>

<script>

  import PuckList from '@/components/puck/PuckList'
  import PuckDialog from '@/components/puck/PuckDialog'

  export default {
    name: 'Pucks',
    components: {
      PuckList,
      PuckDialog
    },
    data: () => ({
      showDialog: false
    }),
    methods: {
      showPuckDialog() {
        this.showDialog = true
      }
    },
    computed: {
      pucks() {
        return this.$store.state.pucks
      }
    },
    created() {
      this.$store.dispatch('getPucks', {})
      this.$store.commit('updateAppBarFab', {
        color: 'pink',
        callback: this.showPuckDialog
      })
    }
  }
</script>
